import userStore from 'shared/stores/user.store'
import countriesStore from 'shared/stores/countries.store'
import currenciesStore from 'shared/stores/currencies.store'
import sellerStore from 'shared/stores/seller.store'
import payerStore from 'shared/stores/payer.store'
import publisherStore from 'shared/stores/publisher.store'
import teamMemberStore from 'shared/stores/teamMember.store'
import i18nStore from 'shared/stores/i18n.store'
import docImprintStore from 'shared/stores/docImprint.store'
import docPrivacyStore from 'shared/stores/docPrivacy.store'
import docTermsStore from 'shared/stores/docTerms.store'

import trackingLogsStore from 'shared/stores/trackingLogs.store'
import bookingStore from '../cabinet/stores/booking.store'

import appStore from './stores/appStore'
import emailsStore from './stores/emails.store'
import signUpFormStore from './stores/signUp.store'
import elopagePromosStore from './stores/elopagePromos.store'
import surveyStore from './stores/survey.store'

export default {
  userStore,
  countriesStore,
  currenciesStore,
  sellerStore,
  payerStore,
  publisherStore,
  teamMemberStore,
  trackingLogsStore,
  bookingStore,
  appStore,
  emailsStore,
  signUpFormStore,
  elopagePromosStore,
  surveyStore,
  i18nStore,
  docImprintStore,
  docPrivacyStore,
  docTermsStore,
}
