import { action, computed, makeObservable } from 'mobx'

import { REGISTRATION_STEPS } from 'constants/registration.constants'
import { PRIVACY_FORMS } from 'constants/privacyPolicy.constants'
import { DELETION_STATES } from 'constants/sellerDeletion.constants'

import { postTrafficCookies } from 'shared/api/trafficSourceCookies.api'
import { getCookies } from 'libs/common/cookies'
import { PAYMENT_FORMS, PAYMENT_METHOD_BY_PRICING_PLAN } from 'constants/paymentSettingShared.constants'
import { KLARNA_KEY } from 'constants/options.constants'
import * as api from '../api/seller.api'
import * as ssApi from '../../cabinet/api/sellerSetting.api'
import SharedStore from './shared.store'
import userStore from './user.store'

export class SellerStore extends SharedStore<api.Seller> {
  storeName = 'SellerStore'
  childApi = api // hack to make api visible in abstract store
  // CRUD methods inside SharedStore, please check them before overwrite
  // in 99% cases it's enough to use them with promise.then() to do after-actions

  isAppActive = (key) => {
    const { optionKeys } = this.item
    return optionKeys && optionKeys.includes(key)
  }

  @action setAppKey = (app, val) => {
    if (val) {
      this.item.optionKeys.push(app)
    }
  }

  @action createWistiaProject = async () => {
    const resp = (await ssApi.createWistiaProject()) as any
    this.item.wistiaProjectId = resp.wistiaProjectId
  }

  constructor() {
    super()

    makeObservable(this)
  }

  @computed get registrationStates() {
    const { confirmed } = userStore.item
    const { profileDone, imprintDone, paymentAccountDone, privacyForm, vatLiabilityText } = this.item

    return {
      [REGISTRATION_STEPS.verifyEmail]: confirmed,
      [REGISTRATION_STEPS.imprint]: imprintDone,
      [REGISTRATION_STEPS.profile]: profileDone,
      [REGISTRATION_STEPS.invoiceSetup]: vatLiabilityText && vatLiabilityText.length > 0,
      [REGISTRATION_STEPS.privacyPolicy]: privacyForm && privacyForm !== PRIVACY_FORMS.none,
      [REGISTRATION_STEPS.paymentSettings]: paymentAccountDone,
    }
  }

  @computed get isInDeactivationPhase() {
    const { deletionState, deletionRequest } = this.item

    return deletionState !== DELETION_STATES.empty && deletionRequest != null && deletionState !== 0
  }

  async logTrafficCookies() {
    const cookies = getCookies('initialTrafficSource', false)

    if (!cookies) {
      return
    }

    if (!this.item.id) {
      this.setExpands(['user_profile'])
      await this.fetchItem()
    }

    postTrafficCookies({ value: cookies, profile_id: this.item.id })
  }

  isAllowedByAppsPayForms = (payForm: string) => {
    const payForms = { [PAYMENT_FORMS.klarna]: this.isAppActive(KLARNA_KEY) }

    return payForms[payForm] || !payForms.hasOwnProperty(payForm)
  }

  getPossiblePaymentMethodForPricingPlan = (pricingPlan: string, currencyId: number): string[] => {
    const { possiblePaymentMethods } = this.item
    if (!possiblePaymentMethods || !possiblePaymentMethods[currencyId] || !possiblePaymentMethods[currencyId].length)
      return []

    return possiblePaymentMethods[currencyId].filter((paymentMethod) => {
      if (this.isAllowedByAppsPayForms(paymentMethod)) {
        return (PAYMENT_METHOD_BY_PRICING_PLAN[pricingPlan] || []).includes(paymentMethod)
      } else {
        return false
      }
    })
  }

  getAllowedPaymentMethodForPricingPlan = (pricingPlan: string, currencyId: number): string[] => {
    const { allowedPaymentMethods } = this.item
    if (!allowedPaymentMethods || !allowedPaymentMethods[currencyId] || !allowedPaymentMethods[currencyId].length)
      return []

    return allowedPaymentMethods[currencyId].filter((paymentMethod) => {
      if (this.isAllowedByAppsPayForms(paymentMethod)) {
        return (PAYMENT_METHOD_BY_PRICING_PLAN[pricingPlan] || []).includes(paymentMethod)
      } else {
        return false
      }
    })
  }
}

export default new SellerStore()
